import { render, staticRenderFns } from "./NewMarketplace.vue?vue&type=template&id=6a8e9b6e&scoped=true&"
import script from "./NewMarketplace.vue?vue&type=script&lang=js&"
export * from "./NewMarketplace.vue?vue&type=script&lang=js&"
import style0 from "./NewMarketplace.vue?vue&type=style&index=0&id=6a8e9b6e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8e9b6e",
  null
  
)

export default component.exports